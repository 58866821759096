:root {
  --good: #52cc1c;
  --danger: #ff0000;
  --danger-light: #ffebeb;
  --warn: #f6c01e;
  --warn-light: #fef6dd;
  --primary-cta: #036ae2;
  --primary: #355d8b;
  --primary-dark: #4e4e4e;
  --primary-light: #f2f6fc;
  --medium-slate: #878c93;
  --dark-slate: #323c47;
  --light-slate: #d0d7dd;
  --white: #ffffff;
  --gray: #9b9b9b;
  --dark: #2b3238;
}
.Toastify {
  position: fixed;
  z-index: 100000;
}
